<template>
    <v-card :loading="loading">
        <v-toolbar flat>
            <v-toolbar-title>Список</v-toolbar-title>
        </v-toolbar>
        <v-list>
            <v-list-item
                v-for="l in list"
                :key="l.id"
                :to="{ name: 'CatOne', params: { id: l.id } }"
            >
                <v-list-item-icon>
                    <v-icon>mdi-folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ l.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template> 

<script>
export default {
    name: "CatList",
    data: () => ({
        loading: false,
        list: [],
    }),
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            this.loading = true;
            const list = await this.$admin("/category");
            this.list = list;
            this.loading = false;
        },
    },
};
</script>
